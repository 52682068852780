import React from "react";

import Layout from "../../components/layout";
import { Seo } from "../../components";
// import AboutProfile from "../../components/about-profile";

// import WithService from "../../service/withService";

import image19 from "../../images/about/rev-sumodh.jpg";
import image20 from "../../images/about/mr-joseph-thomas.jpg";

// const CorporateManager = ({ loading, response, error }) => {
//   return <AboutProfile loading={loading} response={response} />;
// };

const CorporateOffice = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Corporate Manager’s Office" />
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="about-subtitle">Corporate Manager’s Office</h3>
              {/* <div className="row">
                <WithService endpoint="GetCorporateManager">
                  <CorporateManager />
                </WithService>
              </div> */}
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="about-image-wrapper about-image-wrapper_extra-small">
                    <img src={image19} className="w-100" alt="image19"></img>
                  </div>
                </div>
                <div className="col-12 col-md-9">
                  <p>
                    <b>Rev. Sumod Chacko Cherian</b>
                    <br />
                    Ph: 8547622079
                    <br />
                    <br />
                    Manager,
                    <br />
                    CMS Schools
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="about-image-wrapper about-image-wrapper_extra-small">
                    <img src={image20} className="w-100" alt="image20"></img>
                  </div>
                </div>
                <div className="col-12 col-md-9">
                  <p>
                    <b>Mr. Joseph Thomas</b>
                    <br />
                    Ph: 9447869923
                    <br />
                    <br />
                    Manager,
                    <br />
                    Self Financing Schools
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CorporateOffice;
